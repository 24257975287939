<template>
    <div>
        <template v-if="layoutView.view !== 'mobile'">
            <div class="gibs-footer-desktop-view">
                <hr class="gibs-divider divider-1">
                <div class="gibs-logo-contact-container">
                    <div class="gibs-footer-logo-container">
                        <div class="gibs-footer-logo">
                            <nuxt-icon name="gibs-logo-full" />
                        </div>
                        <!-- <img class="gibs-footer-logo" src='/gibs-logo-full.svg'/> -->
                    </div>
                    <div class="gibs-details-container">
                        <div class="gibs-address">
                            <span>Address</span> <br>
                            <p>
                                26 Melville Road <br>
                                Illovo, Johannesburg <br>
                                2196
                            </p>
                        </div>
                        <div class="gibs-contacts">
                            <span>Contact</span><br>
                            <strong>We're available to help</strong><br>
                            <p>
                                Monday to Friday, 08h00 - 17h00.
                            </p>
                            <p class="gibs-call-container">
                                Call &nbsp
                                <GibsControlTextLink :textLinkSpec="contactNumberLink" />
                            </p>
                            <p>
                                <GibsControlTextLink :textLinkSpec="SendEmailLink" />
                            </p>
                            <GibsControlSolidButton id="subscribe-newsletter-btn" class="gibs-subscribe-btn"
                                :solidButtonSpec="newsletterSpec" @click="emitOpenModal" />
                        </div>
                    </div>
                </div>

                <div class="gibs-accreditation-list">
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-gbsn" :to='gbsnUrl' external target="_blank">
                            <nuxt-icon class="icon-lg" :name='gbsnIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-amba" :to='ambaUrl' external target="_blank">
                            <nuxt-icon class="icon-base" :name='ambaIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-aabs" :to='aabsUrl' external target="_blank">
                            <nuxt-icon class="icon-lg" :name='aabsIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-embac" :to='embacUrl' external target="_blank">
                            <nuxt-icon class="icon-base" :name='embacIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-efmd" :to='efmdUrl' external target="_blank">
                            <nuxt-icon class="icon-xs" :name='efmdIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-aacsb" :to='aacsbUrl' external target="_blank">
                            <nuxt-icon class="icon-base" :name='aacsbIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-aom" :to='aomUrl' external target="_blank">
                            <nuxt-icon class="icon-base" :name='aomIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-sabsa" :to='sabsaUrl' external target="_blank">
                            <nuxt-icon class="icon-sm" :name='sabsaIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-prme" :to='prmeUrl' external target="_blank">
                            <nuxt-icon class="icon-xs" :name='prmeIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-ceeman" :to='ceemanUrl' external target="_blank">
                            <nuxt-icon class="icon-xs" :name='ceemanIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-che" :to='cheUrl' external target="_blank">
                            <nuxt-icon class="icon-sm" :name='cheIcon' />
                        </NuxtLink>
                    </div>
                    <div class="gibs-accreditation">
                        <NuxtLink id="accreditation-unicon" :to='uniconUrl' external target="_blank">
                            <nuxt-icon class="icon-base" :name='uniconIcon' />
                        </NuxtLink>
                    </div>
                </div>

                <hr class="gibs-divider divider-2">

                <div class="gibs-extra-links-container">
                    <div class="gibs-copyright-and-links">
                        <div id="gibs-extra-link-list" class="gibs-links-container">
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-gibsMba" :to='gibsMbaUrl'>GIBS MBA</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-info" :to='infoUrl'>Info Central</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-up" :to='upUrl' external target="_blank">University of Pretoria</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-vacancies" :to='vacanciesUrl' external target="_blank">Vacancies</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-sitemap" :to="sitemapUrl">Sitemap</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-legal" :to='legalUrl'>Legal</NuxtLink>
                            </span>
                            <span class="gibs-footer-extra-links">
                                <NuxtLink id="extra-link-contactus" :to="contactusUrl">Contact Us</NuxtLink>
                            </span>
                        </div>
                        <div class="gibs-copyright-container">
                            <span id="gibs-copyright" class="gibs-copyright">
                                Copyright &#169; {{ year }} GIBS. All Rights Reserved.
                            </span>
                        </div>
                    </div>

                    <ul id="gibs-social-media" class="gibs-social-media">
                        <li><NuxtLink id="social-media-fb" :to='fbUrl' external target="_blank"><nuxt-icon class="social-media-icon"
                                    :name='fbIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-twitter" :to='twitterUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name="twitterIcon" /></NuxtLink></li>
                        <li><NuxtLink id="social-media-instagram" :to='instagramUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='instagramIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-youtube" :to='youtubeUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='youtubeIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-tiktok" :to='tikTokUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='tikTokIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-linkedin" :to='linkedinUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='linkedinIcon' /></NuxtLink></li>
                      
                    </ul>
                </div>
            </div>
        </template>

        <template v-else-if="layoutView.view === 'mobile'">
            <div class="gibs-footer-mobile-view">
                <hr class="gibs-divider mobile-divider-1">

                <div class="gibs-mobile-social-media-container">
                    <ul id="gibs-social-media" class="gibs-mobile-social-media">
                        <li><NuxtLink id="social-media-fb" :to='fbUrl' external target="_blank"><nuxt-icon class="social-media-icon"
                                    :name='fbIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-twitter" :to='twitterUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='twitterIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-instagram" :to='instagramUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='instagramIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-youtube" :to='youtubeUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='youtubeIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-tiktok" :to='tikTokUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='tikTokIcon' /></NuxtLink></li>
                        <li><NuxtLink id="social-media-linkedin" :to='linkedinUrl' external target="_blank"><nuxt-icon
                                    class="social-media-icon" :name='linkedinIcon' /></NuxtLink></li>
                    </ul>
                </div>

                <div class="gibs-mobile-contacts-container">
                    <div class="gibs-mobile-contacts">
                        <span>
                            <GibsControlTextLink :textLinkSpec="SendEmailLink" />
                        </span>
                        <span>
                            <GibsControlTextLink :textLinkSpec="contactNumberLink" />
                        </span>
                    </div>
                </div>

                <div class="gibs-mobile-accreditation-container">
                    <div class="gibs-mobile-accreditation-list">
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-gbsn" :to='gbsnUrl' external target="_blank">
                                <nuxt-icon class="icon-lg" :name='gbsnIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-amba" :to='ambaUrl' external target="_blank">
                                <nuxt-icon class="icon-base" :name='ambaIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-aabs" :to='aabsUrl' external target="_blank">
                                <nuxt-icon class="icon-lg" :name='aabsIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-embac" :to='embacUrl' external target="_blank">
                                <nuxt-icon class="icon-base" :name='embacIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-efmd" :to='efmdUrl' external target="_blank">
                                <nuxt-icon class="icon-xs" :name='efmdIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-aacsb" :to='aacsbUrl' external target="_blank">
                                <nuxt-icon class="icon-base" :name='aacsbIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-aom" :to='aomUrl' external target="_blank">
                                <nuxt-icon class="icon-base" :name='aomIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-sabsa" :to='sabsaUrl' external target="_blank">
                                <nuxt-icon class="icon-sm" :name='sabsaIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-prme" :to='prmeUrl' external target="_blank">
                                <nuxt-icon class="icon-xs" :name='prmeIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-ceeman" :to='ceemanUrl' external target="_blank">
                                <nuxt-icon class="icon-xs" :name='ceemanIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-che" :to='cheUrl' external target="_blank">
                                <nuxt-icon class="icon-sm" :name='cheIcon' />
                            </NuxtLink>
                        </div>
                        <div class="gibs-accreditation">
                            <NuxtLink id="accreditation-unicon" :to='uniconUrl' external target="_blank">
                                <nuxt-icon class="icon-base" :name='uniconIcon' />
                            </NuxtLink>
                        </div>
                    </div>
                </div>

                <div id="gibs-mobile-extra-link-list" class="gibs-mobile-extra-link-container">
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-gibsMba" :to='gibsMbaUrl'>GIBS MBA</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-info" :to='infoUrl'>Info Central</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-up" :to='upUrl' external target="_blank">University of Pretoria</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-vacancies" :to='vacanciesUrl' external target="_blank">Vacancies</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-sitemap" :to='sitemapUrl'>Sitemap</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-legal" :to='legalUrl'>Legal</NuxtLink>
                    </span>
                    <span class="gibs-footer-extra-links">
                        <NuxtLink id="extra-link-contactus" :to='contactusUrl'>Contact Us</NuxtLink>
                    </span>
                </div>
                <div class="gibs-subscribe-mobile-container">
                    <GibsControlSolidButton id="subscribe-newsletter-btn" class="gibs-subscribe-btn"
                        :solidButtonSpec="newsletterSpec" @click="emitOpenModal" />
                </div>

                <hr class="gibs-divider mobile-divider-2">

                <div id="gibs-copyright" class="gibs-mobile-copyright-container">
                    Copyright &#169; {{ year }} GIBS. All Rights Reserved.
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">

const props = defineProps(["layoutView", "isModalOpen"]); 

const emit = defineEmits(['toggleModal']);

function emitOpenModal() {
  emit('toggleModal');
}

const year = new Date().getFullYear();
const subscribeUrl = "/newsletter";
const gbsnUrl = "https://gbsn.org/";
const gbsnIcon = "accreditation/footer-gbsn-logo";
const ambaUrl = "https://www.associationofmbas.com/";
const ambaIcon = "accreditation/footer-amba-logo";
const aabsUrl = "http://www.aabschools.com/";
const aabsIcon = "accreditation/footer-aabs-logo";
const embacUrl = "https://embac.org/";
const embacIcon = "accreditation/footer-embac-logo";
const efmdUrl = "https://efmdglobal.org/";
const efmdIcon = "accreditation/footer-efmd-logo";
const aacsbUrl = "http://www.aacsb.edu/accreditation";
const aacsbIcon = "accreditation/footer-aacsb-logo";
const aomUrl = "http://aom.org/";
const aomIcon = "accreditation/footer-aom-logo";
const sabsaUrl = "http://www.sabsa.co.za/";
const sabsaIcon = "accreditation/footer-sabsa-logo";
const ceemanUrl = "http://www.ceeman.org/";
const ceemanIcon = "accreditation/footer-ceeman-logo";
const cheUrl = "https://www.che.ac.za/";
const cheIcon = "accreditation/footer-che-logo";
const uniconUrl = "https://www.uniconexed.org/";
const uniconIcon = "accreditation/footer-unicon-logo";
const prmeUrl = "http://www.unprme.org/";
const prmeIcon = "accreditation/footer-prme-logo";
const fbUrl = "https://www.facebook.com/GIBSsa";
const fbIcon = "social-media/fb-logo";
const twitterUrl = "https://twitter.com/gibs_sa";
const twitterIcon = "social-media/x-logo";
const instagramUrl = "https://www.instagram.com/gibsbusinessschool/";
const instagramIcon = "social-media/instagram-logo";
const youtubeUrl = "https://www.youtube.com/user/GIBSBusinessSchool";
const youtubeIcon = "social-media/youtube-logo";
const linkedinUrl = "https://www.linkedin.com/school/gibssa/";
const linkedinIcon = "social-media/linkedin-logo";
const gibsMbaUrl = "/programmes/master-of-business-administration--mba-";
const tikTokIcon = 'social-media/tiktok-logo';
const tikTokUrl = 'https://www.tiktok.com/@gibsbusinessschool';
const infoUrl = "/info-central";
const upUrl = "https://www.up.ac.za/";
// Gustav 2024/05/13 - Vacancies to link directly to LinkedIn URL until content is suppied for vacancies page.
//const vacanciesUrl = "/pages/vacancies"; // REMOVE external in Nuxt link
const vacanciesUrl = "https://www.linkedin.com/school/gibssa/jobs/?viewAsMember=true";
const sitemapUrl = "/sitemap";
const legalUrl = "/pages/legal";
const contactusUrl = "/contact-us";

const newsletterSpec: IControlButton = {
    buttonText: "Subscribe to our Newsletter",
    class: "",
    enableUrl: false,
    externalSource: false,
    sourceUrl: "",
}

const SendEmailLink: IControlButton = {
    buttonElName: "gibs-email",
    buttonText: "Send us an Email",
    class: "gibs-email",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "mailto:info@gibs.co.za?subject=Inquiry from GIBS Website Contact Form&body=Dear GIBS Team,%0d%0a%0d%0aI am reaching out through the GIBS website's contact form regarding the following:",
}

const contactNumberLink: IControlButton = {
    buttonElName: "gibs-cell",
    buttonText: "+27 (0) 11 771 4000",
    class: "gibs-cell",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "tel:+27117714000",
}

</script>
